// Variables
$primary_color              : #000000;
$secondary_color            : #ffffff;
$mainMenu_color		        : #ffffff;
$heading_color		        : #1e1e23;
$heading_color2		        : #333333;
$light_color 		        : #f4f4f4;
$light_blue 		        : #01b9f5;
$light_green 		        : #71d875;
$light_orange		        : #f99240;
$white_color				: #ffffff;
$black_color				: #000000;
$yellow_color		        : #edc317;

// Fonts
$primary_font               : 'Raleway', sans-serif;
$secondary_font             : 'Montagu Slab', serif;

// Viewports
$desktop_xxl	            : 1600px;
$desktop_xl		            : 1440px;
$laptop			            : 1366px;
$desktop		            : 1280px;
$desktop2		            : 1199px;
$desktopSmall	            : 1024px;
$tablet			            : 992px;
$tablet2		            : 991px;
$tabletSmall	            : 768px;
$mobileBig		            : 767px;
$mobileMed		            : 576px;
$mobile			            : 480px;
$mobileSmall	            : 375px;
$mobilexs      	            : 320px;


// New Variables

// COLORS

$color-skyblue-light: #f4f9ff;

$color-grey-light-1: #abadb0;
$color-grey-light-2: #f1f1f1;
$color-grey-light-3: #f8f7fd;

$color-grey-dark: #1d2630;
$color-grey-dark-2: #666;
$color-grey-dark-3: #333;

.display-flex {
    display: flex !important;
}

@media (max-width: $mobileBig) {

    .margin-bottom-xs-30 {
        margin-bottom: 30px !important;
    }
}
.footerMain {
    background-color: $heading_color;

    &__top {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 50px 0;
        border-bottom: 1px solid #3b3b3f;

        @media (max-width: $mobileBig) {
            padding: 20px 0;
        }

        ul {
            display: flex;
            flex-wrap: wrap;

            margin: 0;

            li {
                display: flex;
                flex-direction: column;

                width: 33.3%;
                padding: 20px 80px;
                border-right: 1px solid #3b3b3f;
                text-align: center;

                &:last-child {
                    border-right: none;
                }

                @media (max-width: $desktop - 1) {
                    padding: 20px 40px;
                }

                @media (max-width: $desktopSmall - 1) {
                    padding: 15px;
                }

                @media (max-width: $mobileBig) {
                    padding: 20px 0;
                    width: 100%;
                    border-right: none;
                    border-bottom: 1px solid #3b3b3f;

                    &:last-child {
                        border-bottom: none;
                    }
                }

                h4 {
                    font-family: $primary_font;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 1.2;
                    color: $white_color;
                    margin: 0 0 15px;
                    text-transform: uppercase;
                }

                p {
                    font-family: $primary_font;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 28px;
                    color: $white_color;
                    margin: 0;

                    a {
                        display: inline-block;
                        color: $white_color;
                    }
                }
            }
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        padding: 30px 0;

        ul {
            margin: 0;

            li {
                display: block;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    font-family: $primary_font;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 1.2;
                    color: $white_color;

                    i {
                        color: $white_color;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}
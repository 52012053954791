.contactusBannerMain {
    position: relative;
    z-index: 0;

    .contactusBanner {
        $contactusBanner: ".contactusBanner";

        text-align: center;

        &__img {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                height: auto;
                object-fit: cover;

                @media (max-width: $desktop - 1) {
                    min-height: 350px;
                }

                @media (max-width: $mobileBig) {
                    min-height: 568px;
                }
            }
        }

        &__content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            width: 100%;
            height: 100%;

            h1 {
                font-family: $primary_font;
                font-weight: 800;
                font-size: 52px;
                line-height: 1.2;
                color: $white_color;
                margin: 0 0 10px;
            }

            p {
                font-family: $primary_font;
                font-weight: 300;
                font-size: 25px;
                line-height: 1.2;
                color: $white_color;
                margin: 0;
                padding: 10px 40px;
                background-color: rgba($black_color, 0.48);
            }
        }
    }
}

.contactusPageMain {

    .contactusPage {
        $contactusPage: ".contactusPage";

        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        &__formBox {
            width: 50%;
            padding-left: 15px;
            padding-right: 15px;

            @media (max-width: $desktopSmall - 1) {
                width: 100%;
                margin-bottom: 80px;
            }

            #{$contactusPage}__heading {
                min-height: 154px;
                margin-bottom: 45px;

                @media (max-width: $desktopSmall - 1) {
                    min-height: auto;
                }

                h2 {
                    font-family: $primary_font;
                    font-weight: 400;
                    font-size: 39px;
                    line-height: 1.2;
                    color: $heading_color;
                    text-transform: uppercase;
                    position: relative;
                    margin-bottom: 50px;

                    &:after {
                        content: "";
                        position: absolute;
                        z-index: 2;
                        left: 0;
                        bottom: -15px;
                        width: 100px;
                        height: 4px;
                        background-color: $heading_color;
                    }
                }

                p {
                    font-family: $primary_font;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: $heading_color;
                    margin: 0;
                }
            }

            #{$contactusPage}__form {

                .formBox {

                    .form-group {
                        margin-bottom: 30px;

                        .form-control {
                            display: inline-block;
                            font-family: $primary_font;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 1.2;
                            color: $heading_color;
                            border: 1px solid $heading_color2;
                            border-radius: 0;
                            padding: 10px 15px;
                            height: 40px;
                            box-shadow: inset 0 1px 1px rgba($black_color, 0.08);

                            &::-webkit-input-placeholder {
                                font-family: $primary_font;
                                font-weight: 600;
                                font-size: 13px;
                                line-height: 1.2;
                                color: $heading_color;
                            }

                            &::-moz-placeholder {
                                font-family: $primary_font;
                                font-weight: 600;
                                font-size: 13px;
                                line-height: 1.2;
                                color: $heading_color;
                            }

                            &:-ms-input-placeholder {
                                font-family: $primary_font;
                                font-weight: 600;
                                font-size: 13px;
                                line-height: 1.2;
                                color: $heading_color;
                            }

                            &:-moz-placeholder {
                                font-family: $primary_font;
                                font-weight: 600;
                                font-size: 13px;
                                line-height: 1.2;
                                color: $heading_color;
                            }
                        }

                        textarea.form-control {
                            height: 180px;
                            resize: vertical;
                        }
                    }

                    button {
                        display: block;
                        width: 100%;
                        font-family: $primary_font;
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 1.2;
                        color: $heading_color;
                        border-radius: 0;
                        padding: 5px 15px;
                        height: 40px;
                        text-transform: uppercase;
                        background-color: $heading_color;
                        color: $white_color;
                        cursor: pointer;
                        border: 1px solid $heading_color;
                        box-shadow: none;

                        &:hover {
                            background: $white_color;
                            color: $heading_color;
                        }
                    }
                }
            }
        }

        &__locationBox {
            width: 50%;
            padding-left: 15px;
            padding-right: 15px;

            @media (max-width: $desktopSmall - 1) {
                width: 100%;
            }

            #{$contactusPage}__heading {
                min-height: 154px;
                margin-bottom: 45px;

                h2 {
                    font-family: $primary_font;
                    font-weight: 400;
                    font-size: 39px;
                    line-height: 1.2;
                    color: $heading_color;
                    text-transform: uppercase;
                    position: relative;
                    margin-bottom: 50px;

                    &:after {
                        content: "";
                        position: absolute;
                        z-index: 2;
                        left: 0;
                        bottom: -15px;
                        width: 100px;
                        height: 4px;
                        background-color: $heading_color;
                    }
                }

                ul {
                    margin: 0;

                    li {
                        display: block;
                        font-family: $primary_font;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: $heading_color;
                        margin: 0 0 10px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        span {
                            font-weight: 600;
                        }

                        a {
                            color: $heading_color;
                        }
                    }
                }
            }

            #{$contactusPage}__location {

                iframe {}
            }
        }
    }
}
.wrapper {
	width: 100%;
	overflow: hidden;
	// margin-top: 80px;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
	padding-right: 15px;
	padding-left: 15px;
}

.container {
	max-width: 1440px;
	width: 96%;
	margin: 0 auto;
	
	@media (max-width: $mobileBig) {
		width: 100%;
	}
}

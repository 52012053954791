.header {
	$header: ".header";

	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 98;
	padding: 20px 0;
	transition: all 0.5s ease;

	.container {
		display: flex;
		align-items: center;
		justify-content: space-between;

		transition: height 0.5s ease-out;
	}

	&__left {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		#{$header}__logo {

			a {
				display: flex;
				align-items: center;
				justify-content: center;

				transition: all 0.25s ease-out;
				width: 245px;

				@media (max-width: $mobileBig) {
					width: 180px;
				}

				@media (max-width: $mobile - 1) {
					width: 125px;
				}

				img {
					width: 100%;
				}
			}
		}
	}

	&__right {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		#{$header}__details {
			display: flex;
			align-items: center;
			justify-content: space-between;

			ul {
				display: flex;
				align-items: center;
				justify-content: flex-end;

				white-space: nowrap;
				transition: all .25s cubic-bezier(.61, .03, .43, .9);

				li {
					display: block;
					margin-left: 20px;

					&:first-child {
						margin-left: 0;
					}

					&.loginBtn {

						a {
							border: 1px solid $white_color;
							border-radius: 20px;
							padding: 7.5px 20px;

							&:hover {
								background-color: $white_color;
								color: $black_color;
							}
						}
					}

					a {
						display: flex;
						align-items: center;
						justify-content: center;

						font-family: $primary_font;
						font-weight: 400;
						font-size: 16px;
						line-height: 1.2;
						color: $mainMenu_color;

						img {
							width: 22px;
							margin-right: 10px;
							transition: all 0.5s ease;
						}

						&:hover {

							img {
								transform: scale(1.08);
							}
						}
					}
				}
			}
		}

		#{$header}__menuTrigger {
			margin-left: 20px;

			@media (max-width: $mobile - 1) {
				margin-left: 10px;
			}

			.headerMenuTrigger {
				display: block;
				position: relative;
				z-index: 0;

				width: 40px;
				height: 40px;
				cursor: pointer;

				@media (max-width: $mobile - 1) {
					width: 30px;
					height: 30px;
				}

				span {
					position: absolute;
					left: 0;
					transform: translateY(-50%);

					width: 100%;
					height: 3px;
					background-color: $white_color;

					&.first {
						top: calc(50% - 9px);
					}

					&.second {
						top: 50%;
					}

					&.third {
						top: calc(50% + 9px);
					}
				}
			}
		}
	}

	&.sticky,
	&.fixed-header {
		background-color: $black_color;
		box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);

		.searchBar {

			.autoSuggestion {
				top: 66px;
			}
		}
	}
	
	&__searchBox {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		padding: 48.5px 0;
		background: $white_color;
		border-top: 1px solid #ddd;
		box-shadow: 0 10px 10px 2px rgba(68, 102, 161, 0.22);
		opacity: 0;
		visibility: hidden;
		transition: all 0.4s ease-out;

		form {
			width: calc(100% - 40px);
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-direction: column;

			.form-group {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				margin: 0;
			}
		}

		&__input {
			width: 100%;
			outline: none;
			border: none;
			flex-grow: 1;
			padding: 0;
			font-size: 18px;
			font-family: $primary_font;
			font-style: italic;
			background: none;
			// border-bottom: 1px solid rgba($secondary_color, 0.5);
			line-height: 1;

			@media (max-width: $mobileBig) {
				width: 100%;
				margin: 10px 0;
			}
		}

		&__close {
			display: flex;

			width: 20px;

			img {
				width: 100%;
			}
		}
		
		&.show {
			opacity: 1;
			z-index: 10;
			visibility: visible;
		}

		@media (max-width: $desktopSmall) {
			padding: 11px 0;
		}
	}
}
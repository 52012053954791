.paginationWrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    .semantic-ui-react-button-pagination {
        display: flex;

        .button {
            margin-left: 4px;
            font-size: $primary_font;
            border: 1px solid #ddd;
            background-color: $white_color;

            &:first-child { margin-left: 0; border-left: 1px solid #ddd; }

            &.currentPage { background-color: $primary_color; color: white; }
        }
    }
}
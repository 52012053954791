.primaryFixedMenu {
    $primaryFixedMenu: ".primaryFixedMenu";

    display: flex;
    flex-direction: column;

    position: fixed;
    top: -100%;
    left: 0;
    z-index: 99;

    background: transparent url(../img/primaryFixedMenu-bg.jpg) no-repeat center center / cover;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($black_color, 0.8);
        z-index: -1;
    }

    &.show {
        top: 0;
    }

    &__top {
        padding: 20px 0;

        > .container {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            transition: height 0.5s ease-out;
        }

        #{$primaryFixedMenu}__trigger {

            .primaryFixedMenuTrigger {
                display: block;
                position: relative;
                z-index: 0;

                width: 40px;
                height: 40px;
                cursor: pointer;

                span {
                    position: absolute;
                    top: 50%;
                    left: 0;

                    width: 100%;
                    height: 3px;
                    background-color: $white_color;

                    &.first {
                        transform: translateY(-50%) rotate(45deg);
                    }

                    &.second {
                        transform: translateY(-50%) rotate(-45deg);
                    }
                }
            }
        }
    }

    &__bottom {
        display: flex;
        align-items: center;

        height: 100%;

        > .container {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        #{$primaryFixedMenu}__box {
            display: flex;
            flex-direction: column;

            ul {
                display: flex;
                flex-direction: column;

                li {
                    display: block;

                    a {
                        display: block;
                        font-family: $primary_font;
                        font-size: 40px;
                        line-height: 1.2;
                        color: rgba($white_color, 0.7);
                        transition: all 0.5s ease;
                        padding: 10px;

                        &:hover,
                        &.active {
                            color: rgba($white_color, 1);
                        }
                    }
                }
            }
        }
    }
}
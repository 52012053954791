.loginFormMain {
    $loginFormMain: ".loginFormMain";

    display: flex;
    align-items: center;
    justify-content: center;

    background: transparent url(../img/loginForm-bg.jpg) no-repeat center center / cover;
    position: relative;
    z-index: 0;
    width: 100vw;
    height: 100vh;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($black_color, 0.5);
        z-index: -1;
    }

    &__box {
        width: 100%;
        max-width: 500px;
        background-color: rgba($black_color, 0.7);
        padding: 50px;
        border-radius: 5px;
        position: relative;

        #{$loginFormMain}__close {
            display: block;
            position: absolute;
            top: 15px;
            right: 15px;
            width: 25px;
            height: 25px;

            .line {
                position: absolute;
                top: 50%;
                left: 0;

                width: 100%;
                height: 2px;
                background-color: $white_color;

                &.first {
                    transform: translateY(-50%) rotate(45deg);
                }

                &.second {
                    transform: translateY(-50%) rotate(-45deg);
                }
            }
        }

        #{$loginFormMain}__form {

            h2 {
                font-family: $primary_font;
                font-weight: 500;
                font-size: 32px;
                line-height: 1.2;
                color: $white_color;
                text-align: center;
                margin: 0 0 50px;
            }

            .form-group {
                margin: 0 0 20px;

                label {
                    font-family: $primary_font;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 1.2;
                    color: $white_color;
                    margin: 0 0 10px;
                }

                .form-control {
                    font-family: $primary_font;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 35px;
                    padding: 5px 15px;
                    height: 35px;
                    color: #555;
                    background-color: $white_color;
                    border: 0;
                    border-radius: 0;
                }
            }

            p {
                font-family: $primary_font;
                font-weight: 700;
                font-size: 14px;
                line-height: 1.2;
                color: $white_color;
                margin: 0 0 20px;
                text-align: center;
            }

            button {
                display: inline-block;
                background-color: #252525;
                color: $white_color;
                width: 100%;
                border: 1px solid $white_color;
                font-family: $primary_font;
                font-weight: 700;
                margin-top: 20px;
                font-size: 15px;
                line-height: 40px;
                transition: all .5s ease;
                letter-spacing: 1px;

                &:hover {
                    background-color: $white_color;
                    color: #000000;
                }
            }
        }
    }
}
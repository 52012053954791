@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "../webfonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "../img/" !default;
$slick-arrow-color: #333 !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }

    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }

    @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider */

.slick-list {

    .slick-loading & {
        background: $white_color slick-image-url("ajax-loader.gif") center center no-repeat;
    }
}

/* Icons */
@if $slick-font-family=="slick" {

    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"),
             slick-font-url("slick.woff") format("woff"),
             slick-font-url("slick.ttf") format("truetype"),
             slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;

    &:hover,
    &:focus {
        outline: none;
        background: transparent;
        color: transparent;

        &:before {
            opacity: $slick-opacity-on-hover;
        }
    }

    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }

    &:before {
        //font-family: $slick-font-family;
        font-family: 'FontAwesome';
        font-size: 24px;
        line-height: 1;
        //color: $slick-arrow-color;
        opacity: $slick-opacity-default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.slick-prev {
    left: -25px;

    [dir="rtl"] & {
        left: auto;
        right: -25px;
    }

    &:before {
        //content: $slick-prev-character;
        content: "\f104";

        [dir="rtl"] & {
            content: "\f104"
        }
    }
}

.slick-next {
    right: -25px;

    [dir="rtl"] & {
        left: -25px;
        right: auto;
    }

    &:before {
        content: "\f105";

        [dir="rtl"] & {
            content: "\f105";
        }
    }
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 0;
}

.slick-dots {
    position: absolute;
    bottom: -80px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    z-index: 100;

    li {
        position: relative;
        display: inline-block;
        height: 10px;
        width: 10px;
        padding: 0;
        margin: 0 10px;
        cursor: pointer;
        background: $white_color;
        text-indent: -9999px;
        border-radius: 8px;
        opacity: 0.3;

        &.slick-active {
            opacity: 1;
        }
    }
}
.homeBannerMain {
    position: relative;
    z-index: 0;

    .homeBanner {
        $homeBanner: ".homeBanner";

        text-align: center;

        &__img {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                height: auto;
                object-fit: cover;

                @media (max-width: $desktop - 1) {
                    min-height: 350px;
                }

                @media (max-width: $mobileBig) {
                    min-height: 568px;
                }
            }
        }

        &__content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            width: 100%;
            height: 100%;

            h1 {
                font-family: $primary_font;
                font-weight: 800;
                font-size: 52px;
                line-height: 1.2;
                color: $white_color;
                margin: 0 0 20px;
            }

            p {
                font-family: $primary_font;
                font-weight: 300;
                font-size: 25px;
                line-height: 1.2;
                color: $white_color;
                margin: 0;
                padding: 5px 20px;
                background-color: rgba($black_color, 0.6);
            }
        }
    }
}

.homeBannerBottomMain {
    margin: 0;
    position: relative;

    .homeBannerBottom {
        $homeBannerBottom: ".homeBannerBottom";

        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;

        &__box {
            display: flex;
            align-items: flex-start;
            justify-content: center;

            width: calc(100% / 3 - 20px);

            #{$homeBannerBottom}__img {
                display: flex;
                align-items: center;
                justify-content: center;

                width: 50px;
                margin: 0;
                margin-right: 15px;

                img {
                    width: auto;
                    height: auto;
                    transition: all 0.5s ease;
                }
            }

            #{$homeBannerBottom}__contant {
                display: flex;
                flex-direction: column;

                width: calc(100% - 65px);

                #{$homeBannerBottom}__title {
                    font-family: $primary_font;
                    font-weight: 600;
                    font-size: 21px;
                    line-height: 1.2;
                    color: $heading_color;
                    margin: 0 0 10px;
                }

                #{$homeBannerBottom}__description {
                    font-family: $primary_font;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 24px;
                    color: #444444;
                    margin: 0;
                }
            }

            &:hover {

                #{$homeBannerBottom}__img {

                    img {
                        transform: scale(1.2);
                    }
                }
            }

            @media (max-width: $desktopSmall - 1) {
                width: calc(100% / 2 - 20px);
                margin-top: 30px;

                &:nth-child(1),
                &:nth-child(2) {
                    margin-top: 0;
                }
            }

            @media (max-width: $mobileBig) {
                width: 100%;
                margin-top: 30px;

                &:nth-child(2) {
                    margin-top: 30px;
                }

                &:nth-child(1) {
                    margin-top: 0;
                }
            }
        }
    }
}

.aboutDymocksCafeMain {

    .aboutDymocksCafe {
        $aboutDymocksCafe: ".aboutDymocksCafe";

        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        position: relative;
        z-index: 0;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: calc(145%);
            height: 100%;
            background-color: $light_color;
            z-index: -1;

            @media (max-width: $desktopSmall - 1) {
                left: -5%;
            }
        }

        &__left {
            width: calc(50% - 30px);

            @media (max-width: $desktopSmall - 1) {
                width: 100%;
                margin-bottom: 30px;
            }

            img {
                width: 100%;
                transition: all 0.5s ease;
            }
        }

        &__right {
            width: calc(50% - 30px);
            margin-left: 60px;
            border-left: 4px solid $white_color;
            padding-left: 30px;

            @media (max-width: $desktopSmall - 1) {
                width: 100%;
                margin-left: 0;
                border-left: none;
                padding-left: 0;
                border-top: 4px solid $white_color;
                padding-top: 30px;
            }

            h2 {
                font-family: $primary_font;
                font-weight: 500;
                font-size: 40px;
                line-height: 1.2;
                color: $heading_color;
                margin: 0 0 40px;
                text-transform: uppercase;

                span {
                    font-weight: 200;
                }
            }

            p {
                font-family: $primary_font;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                color: #444444;
                margin: 0 0 30px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.currentMenuMain {
    background: transparent image('currentMenu-bg.jpg') no-repeat center top / 100% auto;

    .currentMenu {
        $currentMenu: ".currentMenu";

        &__top {
            display: flex;
            flex-direction: column;

            background-color: #f9f9f9;
            padding: 85px 75px 35px;
            border: 1px solid #e3e3e3;
            margin-bottom: 80px;
            position: relative;
            z-index: 0;

            &::before {
                content: "";
                position: absolute;
                bottom: -15vw;
                left: -2.5vw;
                width: 20vw;
                height: 20vw;
                background: transparent url(../img/burger-img.png) no-repeat center center / auto 100%;
                z-index: 1;
            }

            @media (max-width: $mobileBig) {
                padding: 30px;
            }

            #{$currentMenu}__heading {
                display: flex;
                align-items: center;
                flex-direction: column;

                text-align: center;

                h4 {
                    font-family: $primary_font;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 1.2;
                    color: $heading_color;
                    margin: 0 0 20px;
                    text-transform: uppercase;
                }

                h2 {
                    font-family: $primary_font;
                    font-size: 40px;
                    line-height: 1.2;
                    color: $heading_color;
                    padding: 0 0 10px;
                    border-bottom: 1px solid $black_color;
                    margin: 0 0 40px;
                    text-transform: uppercase;
                    position: relative;

                    span {
                        font-weight: 200;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        top: calc(100% + 5px);
                        left: 0;
                        width: 100%;
                        height: 4px;
                        background-color: $black_color;
                    }
                }

                p {
                    font-family: $primary_font;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 24px;
                    color: #444;
                    margin: 0;

                    a {
                        font-weight: 500;
                        display: inline-block;
                        color: #000000;
                        text-decoration: underline;
                    }
                }
            }

            #{$currentMenu}__links {
                margin: 0 0 50px;

                ul {
                    display: flex;
                    flex-wrap: wrap;
                    
                    margin: 0;

                    li {
                        display: block;
                        width: calc((100% / 3) - 14px);
                        margin-right: 21px;

                        &:nth-child(3n+3) {
                            margin-right: 0;
                        }

                        @media (max-width: $desktopSmall - 1) {
                            width: calc((100% / 2) - 10px);
                            margin-right: 20px;

                            &:nth-child(3n+3) {
                                margin-right: 20px;
                            }

                            &:nth-child(2n+2) {
                                margin-right: 0;
                            }
                        }

                        @media (max-width: $mobileBig) {
                            width: 100%;
                            margin-right: 0;
                            margin-top: 20px;

                            &:nth-child(3n+3) {
                                margin-top: 20px;
                                margin-right: 0;
                            }

                            &:nth-child(2n+2) {
                                margin-top: 20px;
                            }

                            &:nth-child(1) {
                                margin-top: 0;
                            }
                        }

                        a {
                            display: block;
                            font-family: $primary_font;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 45px;
                            border-radius: 0;
                            border: 1px solid $black_color;
                            color: $black_color;
                            margin: 50px 0 0;
                            padding: 0 20px 0 35px;
                            height: 45px;
                            position: relative;

                            &::before {
                                content: "";
                                position: absolute;
                                width: 16px;
                                height: 16px;
                                top: 50%;
                                left: 10px;
                                transform: translateY(-50%) rotate(-90deg);
                                background: image("arrow-down.png") no-repeat center center;
                            }

                            @media (max-width: $desktopSmall - 1) {
                                margin: 25px 0 0;
                            }
                        }

                        // &:nth-child(1),
                        // &:nth-child(2),
                        // &:nth-child(3) {

                        //     a {
                        //         margin-top: 0;
                        //     }
                        // }
                    }
                }
            }

            #{$currentMenu}__menu {
                display: flex;
                align-items: center;
                justify-content: center;
                
                text-align: center;

                a {
                    display: block;
                    font-family: $primary_font;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 1.2;
                    border-bottom: 1px solid $black_color;
                    color: $black_color;
                    text-transform: uppercase;
                }
            }
        }

        &__bottom {
            display: flex;
            align-items: center;
            flex-direction: row;

            @media (max-width: $desktopSmall - 1) {
                flex-direction: column;
            }

            #{$currentMenu}__left {
                width: 50%;

                @media (max-width: $desktopSmall - 1) {
                    width: 100%;
                    margin-bottom: 40px;
                }

                .WhatCustomerSay {
                    $WhatCustomerSay: ".WhatCustomerSay";

                    &__slider {
                        padding-bottom: 40px;

                        #{$WhatCustomerSay}__box {
                            display: flex;
                            flex-direction: column;

                            #{$WhatCustomerSay}__heading {
                                display: flex;
                                align-items: center;
                                flex-direction: column;

                                text-align: center;
                                margin: 0 0 50px;

                                p {
                                    font-family: $primary_font;
                                    font-weight: 300;
                                    font-size: 16px;
                                    line-height: 1.2;
                                    color: #444444;
                                    margin: 0 0 10px;
                                }

                                h4 {
                                    font-family: $primary_font;
                                    font-weight: 500;
                                    font-size: 20px;
                                    line-height: 1.2;
                                    color: #444444;
                                    margin: 0;
                                    text-transform: uppercase;
                                }
                            }

                            #{$WhatCustomerSay}__content {
                                display: flex;
                                align-items: center;
                                flex-direction: column;

                                text-align: center;
                                position: relative;

                                &::before {
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 31px;
                                    height: 28px;
                                    background: transparent url(../img/inverted-comma.png) no-repeat center center;
                                }

                                &::after {
                                    content: "";
                                    position: absolute;
                                    right: 0;
                                    bottom: 50px;
                                    width: 21px;
                                    height: 19px;
                                    background: transparent url(../img/inverted-comma2.png) no-repeat center center;
                                }

                                p {
                                    font-family: $primary_font;
                                    font-weight: 300;
                                    font-size: 16px;
                                    line-height: 1.75;
                                    color: #000000;
                                    padding: 0 75px;
                                    margin: 0 0 30px;
                                }

                                h3 {
                                    font-family: $primary_font;
                                    font-weight: 700;
                                    font-size: 15px;
                                    line-height: 1.2;
                                    color: #444444;
                                    margin: 0;
                                }
                            }
                        }

                        .slick-dots {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            bottom: 0;

                            li {
                                height: auto;
                                width: auto;
                                margin: 0;
                                border-radius: 0;
                                background-color: transparent;

                                button {
                                    padding: 0;

                                    &::before {}
                                }
                            }
                        }
                    }
                }
            }

            #{$currentMenu}__right {
                display: flex;
                align-items: center;
                justify-content: center;

                width: 50%;

                @media (max-width: $desktopSmall - 1) {
                    width: 100%;
                }

                .spacialToday {
                    position: relative;
                    z-index: 0;
                    width: 370px;
                    height: 100%;
                    margin: 0 auto;
                    padding: 50px;
                    color: $white_color;
                    overflow: visible;
                    border: 1px solid #e2e2e2;
                    border-color: transparent #e2e2e2;
                    background: transparent url(../img/specialToday-bgMiddle.png) repeat-y center top / 97.3% auto;

                    &::before {
                        content: "";
                        position: absolute;
                        width: 104%;
                        z-index: -1;
                        left: -1px;
                        top: -1px;
                        background: url(../img/specialToday-bgTop.png) no-repeat;
                        height: 160px;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        width: 104%;
                        z-index: -1;
                        left: -1px;
                        bottom: -49px;
                        height: 100px;
                        background: url(../img/specialToday-bgBottom.png) no-repeat;
                    }

                    h2 {
                        font-family: $primary_font;
                        font-weight: 700;
                        font-size: 23px;
                        line-height: 1.2;
                        color: $white_color;
                        margin: 0 0 70px;
                        text-align: center;
                    }

                    h4 {
                        font-family: $primary_font;
                        font-weight: 700;
                        font-size: 17px;
                        line-height: 1.2;
                        color: $white_color;
                        margin: 0 0 15px;
                    }

                    p {
                        font-family: $primary_font;
                        font-weight: 300;
                        font-size: 15px;
                        line-height: 1.75;
                        color: $white_color;
                        margin: 0 0 30px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
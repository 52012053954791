// .mainMenuBannerMain {
//     position: relative;
//     z-index: 0;

//     .mainMenuBanner {
//         $mainMenuBanner: ".mainMenuBanner";

//         text-align: center;

//         &__img {
//             display: flex;
//             align-items: center;
//             justify-content: center;

//             img {
//                 width: 100%;
//                 height: auto;
//                 object-fit: cover;

//                 @media (max-width: $desktop - 1) {
//                     min-height: 350px;
//                 }

//                 @media (max-width: $mobileBig) {
//                     min-height: 568px;
//                 }
//             }
//         }

//         &__content {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             flex-direction: column;

//             position: absolute;
//             top: 50%;
//             left: 50%;
//             transform: translate(-50%, -50%);

//             width: 100%;
//             height: 100%;

//             h1 {
//                 font-family: $primary_font;
//                 font-weight: 800;
//                 font-size: 52px;
//                 line-height: 1.2;
//                 color: $white_color;
//                 margin: 0 0 10px;
//             }

//             p {
//                 font-family: $primary_font;
//                 font-weight: 300;
//                 font-size: 25px;
//                 line-height: 1.2;
//                 color: $white_color;
//                 margin: 0;
//                 padding: 10px 40px;
//                 background-color: rgba($black_color, 0.48);
//             }
//         }
//     }
// }

.discoverMainMenuMain {
    padding-top: 80px;

    .discoverMainMenu {
        $discoverMainMenu: ".discoverMainMenu";

        display: flex;
        flex-direction: column;

        &__top {
            min-height: 136px;

            @media (max-width: $desktop - 1) {
                min-height: 204px;
            }

            @media (max-width: $desktopSmall - 1) {
                min-height: 232px;
            }

            > .container {
                padding-top: 30px;
                padding-bottom: 40px;
                background-color: #f4f4f4;

                @media (max-width: $desktopSmall - 1) {
                    padding-top: 15px;
                    padding-bottom: 15px;
                }

                &.fixedMainMenu {
                    position: fixed;
                    top: 80px;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 1;

                    @media (max-width: $mobileBig) {
                        position: static;
                        transform: translateX(0);
                    }
                }
            }

            #{$discoverMainMenu}__navTabs {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;

                border: none;
                text-align: center;

                // .slick-list {
                //     padding: 0 1px !important;
                // }

                #{$discoverMainMenu}__box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    border: none;
                    border-right: 1px solid $heading_color;
                    border-radius: 0;
                    padding: 0 19.5px;
                    margin: 30px 0 0;

                    font-family: $primary_font;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 1.2;
                    color: $heading_color;
                    text-transform: uppercase;
                    white-space: nowrap;
                    position: relative;

                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5) {
                        margin-top: 0;
                    }

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;
                        border-right: none;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        top: calc(100% + 5px);
                        left: 50%;
                        transform: translateX(-50%);
                        width: 0;
                        height: 1px;
                        background-color: $black_color;
                        transition: all 0.5s ease;

                        @media (max-width: $desktopSmall - 1) {
                            display: none;
                        }
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        top: calc(100% + 8px);
                        left: 50%;
                        transform: translateX(-50%);
                        width: 0;
                        height: 2px;
                        background-color: $black_color;
                        transition: all 0.5s ease;

                        @media (max-width: $desktopSmall - 1) {
                            display: none;
                        }
                    }

                    &.active {
                        background-color: transparent;
                        
                        &::before {
                            width: 75%;
                        }

                        &::after {
                            width: 75%;
                        }

                        @media (max-width: $desktopSmall - 1) {
                            color: $white_color;
                            background-color: $black_color;
                        }
                    }

                    @media (max-width: $laptop - 1) {

                        &:nth-child(5) {
                            margin-top: 30px;
                        }
                    }

                    @media (max-width: $desktop - 1) {
                        margin: 40px 0 0;

                        &:nth-child(4),
                        &:nth-child(5) {
                            margin-top: 40px;
                        }
                    }

                    @media (max-width: $desktopSmall - 1) {
                        padding: 10px;
                        margin: 10px 0 0 !important;
                        border: none;

                        &:first-child {
                            padding-left: 10px;
                        }

                        &:last-child {
                            padding-right: 10px;
                        }
                    }

                    @media (max-width: $mobileBig) {

                        &:first-child {
                            margin-top: 0 !important;
                        }
                    }
                }
            }
        }

        &__bottom {

            #{$discoverMainMenu}__main {
                display: flex;
                flex-direction: column;

                background-color: #fff;
                padding: 50px;
                position: relative;
                z-index: 0;

                @media (max-width: $desktopSmall - 1) {
                    padding: 30px;
                }

                @media (max-width: $mobileBig) {
                    padding: 20px;
                }

                #{$discoverMainMenu}__downloadMenu {
                    position: absolute;
                    top: 50px;
                    right: 50px;
                    width: 125px;
                    text-align: center;
                    z-index: 1;

                    @media (max-width: $desktopSmall - 1) {
                        top: 30px;
                        right: 30px;
                        width: 50px;
                    }

                    @media (max-width: $mobileBig) {
                        top: 20px;
                        right: 10px;
                        width: 40px;
                    }

                    a {
                        display: flex;
                        align-items: center;
                        flex-direction: column;

                        figure {
                            width: 64px;
                            margin: 0 0 10px;

                            @media (max-width: $desktopSmall - 1) {
                                width: 100%;
                                margin: 0;
                            }

                            img {
                                width: 100%;
                            }
                        }

                        span {
                            display: block;
                            font-family: $primary_font;
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 1.2;
                            color: $black_color;

                            @media (max-width: $desktopSmall - 1) {
                                display: none;
                            }
                        }
                    }
                }

                #{$discoverMainMenu}__tabContent {
                    display: flex;
                    flex-direction: column;

                    margin: 0 0 50px;

                    @media (max-width: $desktopSmall - 1) {
                        margin: 0 0 30px;
                    }

                    @media (max-width: $mobileBig) {
                        margin: 0 0 10px;
                    }

                    #{$discoverMainMenu}__box {

                        #{$discoverMainMenu}__header {
                            display: flex;
                            align-items: center;
                            flex-direction: column;

                            text-align: center;
                            padding: 0 0 50px;
                            margin-bottom: 50px;

                            @media (max-width: $desktopSmall - 1) {
                                padding: 0;
                            }

                            @media (max-width: $mobileBig) {
                                margin-bottom: 30px;
                            }

                            figure {
                                width: 32px;
                                margin-bottom: 15px;

                                img {
                                    width: 100%;
                                }
                            }

                            h3 {
                                font-family: $primary_font;
                                font-weight: 300;
                                font-size: 15px;
                                line-height: 28px;
                                color: $heading_color;
                                letter-spacing: 2px;
                                margin: 0;
                                text-transform: uppercase;

                                small {
                                    font-size: 65%;
                                    color: #777;
                                }
                            }

                            h2 {
                                font-family: $primary_font;
                                font-weight: 300;
                                font-size: 33px;
                                line-height: 1.5;
                                color: $heading_color;
                                letter-spacing: 2px;
                                margin: 0 0 10px;
                                text-transform: uppercase;

                                &:last-child {
                                    margin-bottom: 0;
                                }

                                @media (max-width: $mobileBig) {
                                    font-size: 25px;
                                }

                                @media (max-width: $mobile - 1) {
                                    font-size: 20px;
                                }
                            }
                        }

                        #{$discoverMainMenu}__menuLists {
                            display: flex;
                            flex-direction: column;

                            ul {
                                column-count: 2;
                                column-gap: 50px;
                                break-inside: avoid-column;

                                @media (max-width: $desktopSmall - 1) {
                                    column-count: 1;
                                    column-gap: 0;
                                }

                                li {
                                    display: flex;
                                    flex-direction: column;

                                    -webkit-column-break-inside: avoid;
                                    page-break-inside: avoid;
                                    break-inside: avoid;
                                    
                                    margin: 0 0 30px;

                                    h4 {
                                        font-family: $primary_font;
                                        font-weight: 700;
                                        font-size: 19px;
                                        line-height: 1.2;
                                        color: $black_color;
                                        margin: 0 0 10px;

                                        small {
                                            display: inline-block;
                                            font-size: 75%;
                                            font-weight: 700;
                                            line-height: 1;
                                            color: #777777;
                                        }
                                    }

                                    p {
                                        font-family: $primary_font;
                                        font-weight: 300;
                                        font-size: 19px;
                                        line-height: 1.5;
                                        color: $black_color;
                                        margin: 20px 0 0;
                                        padding-right: 20%;
                                        text-transform: capitalize;
                                        position: relative;
                                        z-index: 0;

                                        &:before {
                                            content: "";
                                            position: absolute;
                                            right: 0;
                                            z-index: -1;
                                            border-bottom: 1px dotted #000;
                                            width: 100%;
                                            bottom: 7px;
                                        }

                                        &:first-child {
                                            margin-top: 0;
                                        }

                                        &.withoutBefore {

                                            &:before {
                                                display: none;
                                            }
                                        }

                                        &.subMenu {
                                            font-weight: 300;
                                            font-size: 16px;
                                            margin: 5px 0 0;

                                            span {

                                                &:first-child {
                                                    padding-left: 15px;
                                                }
                                            }
                                        }

                                        span {
                                            background-color: #fff;

                                            &:first-child {
                                                padding-right: 5px;
                                            }

                                            &:last-child {
                                                position: absolute;
                                                bottom: 0;
                                                right: 0;
                                                padding-left: 5px;
                                            }
                                        }
                                    }

                                    i {
                                        display: block;
                                        font-family: $primary_font;
                                        font-weight: 400;
                                        font-style: italic;
                                        font-size: 14px;
                                        line-height: 1.4;
                                        color: $heading_color2;
                                    }
                                }
                            }
                        }
                    }
                }

                #{$discoverMainMenu}__info {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    text-align: center;

                    p {
                        margin: 0;
                    }
                }
            }
        }

        // .container {
        //     display: flex;
        //     flex-direction: column;

        //     background-color: #fff;
        //     padding: 50px;
        //     position: relative;
        //     z-index: 0;

        //     @media (max-width: $desktopSmall - 1) {
        //         padding: 30px;
        //     }
            
        //     @media (max-width: $mobileBig) {
        //         padding: 20px;
        //     }
        // }

        // #{$discoverMainMenu}__mainHeader {
        //     display: flex;
        //     justify-content: center;
        //     flex-wrap: wrap;

        //     text-align: center;
        //     margin-bottom: 50px;

        //     @media (max-width: $desktopSmall - 1) {
        //         margin-bottom: 20px;
        //     }

        //     h1 {
        //         font-family: $secondary_font;
        //         font-weight: 400;
        //         font-size: 50px;
        //         line-height: 1.2;
        //         color: $heading_color;
        //         letter-spacing: 2px;
        //         margin: 0;
        //         text-transform: uppercase;
        //         border-bottom: 1px solid $black_color;
        //         position: relative;
        //         z-index: 0;

        //         &::before {
        //             content: "";
        //             position: absolute;
        //             bottom: 2.5px;
        //             left: 0;
        //             width: 100%;
        //             height: calc(100% - 2.5px);
        //             border-bottom: 1px solid $black_color;
        //             z-index: -1;
        //         }

        //         @media (max-width: $desktopSmall - 1) {
        //             font-size: 40px;
        //         }

        //         @media (max-width: $mobileBig) {
        //             font-size: 30px;
        //         }
        //     }
        // }

        // #{$discoverMainMenu}__box {
        //     margin-bottom: 40px;

        //     @media (max-width: $desktopSmall - 1) {
        //         margin-bottom: 20px;
        //     }

        //     #{$discoverMainMenu}__header {
        //         display: flex;
        //         justify-content: center;
        //         flex-wrap: wrap;

        //         text-align: center;
        //         margin-bottom: 30px;

        //         @media (max-width: $desktopSmall - 1) {
        //             margin-bottom: 20px;
        //         }

        //         h2 {
        //             font-family: $secondary_font;
        //             font-weight: 300;
        //             font-size: 30px;
        //             line-height: 1.2;
        //             color: $heading_color;
        //             letter-spacing: 2px;
        //             margin: 0;
        //             padding: 7.5px;
        //             text-transform: uppercase;
        //             width: 100%;
        //             background: transparent image('menuHeader-bg2.png') no-repeat center center / 100% auto;

        //             @media (max-width: $desktop - 1) {
        //                 font-size: 20px;
        //             }

        //             @media (max-width: $desktopSmall - 1) {
        //                 font-size: 30px;
        //                 line-height: 1.5;
        //             }

        //             @media (max-width: $mobileBig) {
        //                 font-size: 20px;
        //             }
        //         }
        //     }

        //     #{$discoverMainMenu}__menuLists {
        //         display: flex;
        //         flex-direction: column;

        //         ul {

        //             li {
        //                 display: flex;
        //                 flex-direction: column;

        //                 -webkit-column-break-inside: avoid;
        //                 page-break-inside: avoid;
        //                 break-inside: avoid;

        //                 margin: 0 0 20px;

        //                 p {
        //                     font-family: $primary_font;
        //                     font-weight: 300;
        //                     font-size: 16px;
        //                     line-height: 1.2;
        //                     color: $black_color;
        //                     margin: 0 0 10px;
        //                     padding-right: 10%;
        //                     letter-spacing: 0.5px;
        //                     text-transform: uppercase;
        //                     position: relative;
        //                     z-index: 0;

        //                     &:before {
        //                         content: "";
        //                         position: absolute;
        //                         right: 0;
        //                         z-index: -1;
        //                         border-bottom: 1px dotted #000;
        //                         width: 100%;
        //                         bottom: 10px;
        //                     }

        //                     &:last-child {
        //                         margin-bottom: 0;
        //                     }

        //                     &.withoutBefore {

        //                         &:before {
        //                             display: none;
        //                         }
        //                     }

        //                     span {
        //                         background-color: #fff;

        //                         &:first-child {
        //                             padding-right: 5px;
        //                         }

        //                         &:last-child {
        //                             position: absolute;
        //                             bottom: 0;
        //                             right: 0;
        //                             padding-left: 5px;
        //                         }
        //                     }
        //                 }

        //                 i {
        //                     display: block;
        //                     font-family: $primary_font;
        //                     font-weight: 400;
        //                     font-style: italic;
        //                     font-size: 14px;
        //                     line-height: 1.4;
        //                     color: $heading_color2;
        //                 }
        //             }
        //         }
        //     }
        // }

        // #{$discoverMainMenu}__info {
        //     display: flex;

        //     text-align: left;

        //     p {
        //         font-size: 14px;
        //         margin: 0;
        //     }
        // }
    }
}
.spinnerLoader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    text-align: center;
    background: rgba($color: white, $alpha: 0.65);

    &.notBody {
        position: absolute;
    }

    &.domReady {
        // background:url(#{$assetPath}/welcome.gif) center no-repeat $white_color;
        // background-size:cover;
    }

    .loadingCenter {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100px;
        height: 50px;
        margin-top: -25px;
        margin-left: -50px;
        text-align: center;

        .rect {
            display: inline-block;
            background: #000088;
            height: 30px;
            width: 4px;
            -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
            animation: sk-stretchdelay 1.2s infinite ease-in-out;

            &.rect2 {
                -webkit-animation-delay: -1.1s;
                animation-delay: -1.1s;
            }

            &.rect3 {
                -webkit-animation-delay: -1.0s;
                animation-delay: -1.0s;
            }

            &.rect4 {
                -webkit-animation-delay: -0.9s;
                animation-delay: -0.9s;
            }

            &.rect5 {
                -webkit-animation-delay: -0.8s;
                animation-delay: -0.8s;
            }
        }
    }
}

.spinLoader {
    position: relative;
    display: block;
    min-width: 80px;
    min-height: 20px;
}

.loaderBox {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100px;
    height: 50px;
    margin-left: -50px;
    text-align: center;

    .rect {
        display: inline-block;
        background: #101010;
        height: 20px;
        width: 4px;
        -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
        animation: sk-stretchdelay 1.2s infinite ease-in-out;

        &.rect2 {
            -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
        }

        &.rect3 {
            -webkit-animation-delay: -1.0s;
            animation-delay: -1.0s;
        }

        &.rect4 {
            -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
        }

        &.rect5 {
            -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
        }
    }
}

@-webkit-keyframes sk-stretchdelay {

    0%,
    40%,
    100% {
        -webkit-transform: scaleY(0.4)
    }

    20% {
        -webkit-transform: scaleY(1.0)
    }
}

@keyframes sk-stretchdelay {

    0%,
    40%,
    100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }

    20% {
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
    }
}